<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
      <base-service>
        <template #title>
          <h2>Состояние услуги "Голосовая рассылка"</h2>
        </template>
        <template #body>
          <base-table v-if="items.length" :fields="fields" :items="items">
            <template #headerText> Список заданий для звонка </template>
            <template #cell(Caption)="{item}">
              {{ item.Caption }}
            </template>
            <template #cell(manage)="{item}">
              <router-link
                :to="{
                  path: 'auto-dialer/details',
                  query: { id: item.Id }
                }"
              >
                Открыть подробности
              </router-link>
              <br />
              <router-link
                :to="{
                  name: 'DialerEdit',
                  query: { id: item.Id }
                }"
                style="display: block; margin-top: 0.5rem"
              >
                Настройки
              </router-link>
            </template>
          </base-table>
          <base-table v-else>
            <template #headerText>Список заданий для звонка</template>
            <slot>Отсутствуют настроенные рассылки</slot>
          </base-table>
        </template>
      </base-service>
    </slot>
  </the-wrapper>
</template>

<script>
import BaseService from '@/components/shared/BaseService.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import BaseTable from '@/components/shared/BaseTable.vue';
import { getServiceData } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      fields: [
        { key: 'Caption', label: 'Название' },
        { key: 'PreviousMonthCounter', label: 'Предыдущий месяц' },
        { key: 'CurrentMonthCounter', label: 'Текущий месяц' },
        { key: 'manage', label: 'Управление' }
      ],
      items: [],
      isSpinner: true
    };
  },

  components: {
    BaseService,
    BaseTable,
    TheWrapper
  },

  created() {
    getServiceData(URL.AUTO_DIALER)
      .then((result) => {
        if (result.Error) return;
        this.items.push(...result);
      })
      .then(() => this.SHOW_LOADING(false))
      .catch((e) => catchFunction(e));
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' }),

    lookDetails(itemId) {
      this.$router.push({
        path: 'auto-dialer/details',
        query: { id: itemId }
      });
    }
  }
};
</script>

<style></style>
