var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-wrapper',{attrs:{"isSpinner":_vm.isSpinner}},[_vm._t("default",[_c('base-service',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',[_vm._v("Состояние услуги \"Голосовая рассылка\"")])]},proxy:true},{key:"body",fn:function(){return [(_vm.items.length)?_c('base-table',{attrs:{"fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" Список заданий для звонка ")]},proxy:true},{key:"cell(Caption)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Caption)+" ")]}},{key:"cell(manage)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                path: 'auto-dialer/details',
                query: { id: item.Id }
              }}},[_vm._v(" Открыть подробности ")]),_c('br'),_c('router-link',{staticStyle:{"display":"block","margin-top":"0.5rem"},attrs:{"to":{
                name: 'DialerEdit',
                query: { id: item.Id }
              }}},[_vm._v(" Настройки ")])]}}],null,false,109502919)}):_c('base-table',{scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v("Список заданий для звонка")]},proxy:true}])},[_vm._t("default",[_vm._v("Отсутствуют настроенные рассылки")])],2)]},proxy:true}])})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }